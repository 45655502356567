import $ from './jquery';

export default function alert(source, message, type) {
	const wrapper = $(source).closest('.js-alert-container').children('.js-alert-wrapper');

	const element = $(`
		<div class="alert alert-${type} alert-dismissible fade show mb-2" role="alert">
			${message}
			<button type="button" class="close" data-dismiss="alert" aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	`);
	wrapper.append(element);

	setTimeout(() => {
		element.alert('close');
	}, 5000);
}
