/* global document */
import netteForms from 'nette-forms';

import $ from './jquery';

function getErrorElement(element) {
	const $element = $(element);

	if ($element.data('toggle') === 'datetimepicker') {
		const $datePicker = $($element.data('target'));
		if ($datePicker.length) {
			return [$datePicker.get(0), $datePicker];
		}
	}

	return [element, $element];
}

function getErrorTarget($element) {
	const $target = $element.closest('.form-group');
	if ($target.length) {
		return [$target.get(0), $target];
	}

	const $parent = $element.parent();
	return [$parent.get(0), $parent];
}

netteForms.showFormErrors = function showFormErrors(form, errors) {
	const displayed = [];
	let focusElem;

	$(form).find('.is-invalid').removeClass('is-invalid');
	$(form).find('.js-form-error').remove();

	for (let i = 0; i < errors.length; i += 1) {
		const { message } = errors[i];
		const [element, $element] = getErrorElement(errors[i].element);
		const [target, $target] = getErrorTarget($element);

		if (!displayed.some((error) => error.target === target && error.message === message)) {
			$target.addClass('is-invalid');
			$target.append($('<span></span>').addClass('invalid-feedback js-form-error').text(message));
			displayed.push({ target, message });
		}

		$element.addClass('is-invalid');
		if ($element.attr('type') === 'radio' || $element.attr('type') === 'checkbox') {
			($target.is('.form-group') ? $target.children('.form-check,.form-check-nps') : $target).addClass('is-invalid');
		}

		if (!focusElem && element.focus) {
			focusElem = element;
		}
	}

	if (focusElem) {
		focusElem.focus();
	}
};

netteForms.validators.NetvorAccentureSurveyToolFormsValidator_validateDateTime = (elem, args, val) => {
	const re = /^[0-9]{4}-(0?[0-9]|1[0-2])-([0-2]?[0-9]|3[01])([T ]([01]?[0-9]|2[0-3]):[0-5]?[0-9](:[0-5]?[0-9])?)?$/;
	return re.test(val);
};

netteForms.initOnLoad();

$(document).on('change', '.is-invalid', function invalidChanged(e) {
	const $this = $(this);

	if ($this.is('.form-group')) {
		let valid = true;
		const elements = e.target.form ? Array.prototype.filter.call(e.target.form.elements, (element) => (
			$(element).closest('.form-group').is($this)
		)) : [e.target];
		for (let i = 0; i < elements.length; i += 1) {
			if (!netteForms.validateControl(elements[i], null, true)) {
				valid = false;
				break;
			}
		}

		if (valid) {
			$this.removeClass('is-invalid');
			$this.find('.is-invalid').removeClass('is-invalid');
			$this.find('.js-form-error').remove();
		}
	} else if (!$this.closest('.form-group').length) {
		if (netteForms.validateControl(e.target, null, true)) {
			$this.removeClass('is-invalid');
			$this.find('.js-form-error').remove();
		}
	}
});
