/* global document, window */
import naja from 'naja';
import netteForms from 'nette-forms';

import '../shared';
import alert from '../shared/js/alert';
import $ from '../shared/js/jquery';
import './scss/index.scss';

naja.formsHandler.netteForms = netteForms;

document.addEventListener('DOMContentLoaded', naja.initialize.bind(naja));

naja.registerExtension({
	initialize() {
		naja.uiHandler.addEventListener('interaction', this.onInteraction);
		naja.addEventListener('success', this.onSuccess);
		naja.addEventListener('error', this.onError);
	},
	onInteraction: (event) => {
		const { element, options } = event.detail;
		if (!element) {
			return;
		}

		const form = element.hasAttribute('data-naja-survey-form') ? element : element.form;
		if (form?.hasAttribute('data-naja-survey-form')) {
			const value = element.getAttribute('data-naja-survey-form') ?? element.form?.getAttribute('data-naja-survey-form');
			options.surveyForm = value !== 'off' ? form : false;
		}
	},
	onSuccess: (event) => {
		if (!event.detail.options.surveyForm) {
			return;
		}

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	},
	onError: (event) => {
		if (!event.detail.options.surveyForm) {
			return;
		}

		alert(event.detail.options.surveyForm, 'Network error occured. Please try again.', 'danger');

		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	},
});

$(document).on('keyup', 'input.js-auto-check', (event) => {
	const $input = $(event.target);
	const value = $input.val();
	if (value === '') {
		return;
	}

	const $target = $($input.data('target'));
	const name = $target.attr('name');
	if (
		name !== undefined
		&& $target.is(':radio')
		&& $target.closest('form').find(`input[name="${name}"]:checked`).length > 0
	) {
		return;
	}

	$target.prop('checked', true);
});
